import { useEffect, useState } from "react";
import { firestore } from "../../firebase";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useAuth } from "../../contexts/AuthContext";
import {
  collection,
  orderBy,
  query,
  limit,
  onSnapshot,
  startAfter,
  getDocs,
} from "firebase/firestore";
import {
  Box,
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { CircularProgressWithLabel } from "./components/Percentage";
import { styled } from "@mui/system";

// Styled components
const MainKeywordBoxStyled = styled(Box)({
  width: "440px",
});

const DownloadBoxStyled = styled(Box)({
  width: "150px",
});

export const HistoryList = () => {
  const perPage = 10;
  const [data, setData] = useState([]);
  const [lastSnapshot, setLastSnapshot] = useState(null);
  const { currentUser } = useAuth();
  const storage = getStorage();

  useEffect(() => {
    let initQueryInstance = query(
      collection(firestore, "users", currentUser.email, "groupings"),
      orderBy("timestamp", "desc"),
      limit(perPage)
    );

    onSnapshot(initQueryInstance, (querySnapshot) => {
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      // set last data of the snapshot
      setLastSnapshot(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setData(data);
    });
  }, []);

  async function showMore() {
    let initQueryInstance = query(
      collection(firestore, "users", currentUser.email, "groupings"),
      orderBy("timestamp", "desc"),
      startAfter(lastSnapshot),
      limit(perPage)
    );
    const querySnapshot = await getDocs(initQueryInstance);
    let newData = [];
    querySnapshot.forEach((doc) => {
      newData.push({ ...doc.data(), id: doc.id });
    });
    // set last data of the snapshot
    setLastSnapshot(querySnapshot.docs[querySnapshot.docs.length - 1]);
    setData(data.concat(newData));
  }

  // Download CSV File
  function downloadFile(path) {
    getDownloadURL(ref(storage, path))
      .then((url) => {
        window.open(url, "_blank").focus();
      })
      .catch((error) => {
        // Handle any errors
      });
  }

  return (
    <Grid item xs={12}>
      <List
        sx={{
          width: "100%",
          minWidth: "1024px",
        }}
      >
        {data.map((val) => (
          <ListItem
            key={val.id}
            disablePadding
            alignItems="flex-start"
            // divider={true}
            sx={{
              borderRadius: "10px",
              marginTop: "4px",
              background: "#efefff",
              padding: "10px",
            }}
          >
            {/* percentage Bar */}
            <ListItemAvatar>
              {val.status == "done" ? (
                <DoneIcon style={{ margin: 8 }} color="success" />
              ) : (
                <CircularProgressWithLabel
                  value={
                    (val.keywords_received * 100) / val.keywords_total >= 100
                      ? "99%"
                      : (val.keywords_received * 100) / val.keywords_total
                  }
                />
              )}
            </ListItemAvatar>

            <MainKeywordBoxStyled style={{ width: "250px" }}>
              <ListItemText primary="ID" secondary={val.id} />
            </MainKeywordBoxStyled>

            <MainKeywordBoxStyled>
              <ListItemText
                primary={val.keywords_main ? val.keywords_main : "---"}
                // secondary="Main Keyword"
                secondary={"Main Keyword"}
              />
            </MainKeywordBoxStyled>

            <Box sx={{ width: "200px" }}>
              <ListItemText
                primary={
                  val.timestamp
                    ? val.timestamp
                        .toDate()
                        .toDateString()
                        .replace(/^\S+\s/, "")
                    : ""
                }
                secondary="Uplaod Date"
              />
            </Box>

            <Box sx={{ width: "200px" }}>
              <ListItemText
                primary={val.keywords_total}
                secondary="Total Kwd."
              />
            </Box>

            {/* <Box sx={{ width: "200px" }}>
              <ListItemText
                primary={val.keywords_received}
                secondary="Collected Kwd."
              />
            </Box> */}

            <Box sx={{ width: "150px" }}>
              <Typography sx={{ marginTop: "13px" }}>{val.status}</Typography>
            </Box>

            {/* Download button styled */}
            <DownloadBoxStyled>
              {val.resultFileFullPath ? (
                <Button
                  sx={{ marginTop: "10px" }}
                  size="small"
                  color="secondary"
                  onClick={() => downloadFile(val.resultFileFullPath)}
                >
                  Download
                </Button>
              ) : (
                ""
              )}
            </DownloadBoxStyled>
          </ListItem>
        ))}
        <Button onClick={showMore}>show more</Button>
      </List>

      {/* {data.map((val) => (
        <div>
          <h2>{val.fileName}</h2>
          <p>keywords total: {val.keywords_total}</p>
          <p>keywords received: {val.keywords_received}</p>
          <p>csv File: {val.resultFileFullPath}</p>
          {val.resultFileFullPath && (
            <p>
              <button onClick={() => downloadFile(val.resultFileFullPath)}>
                Download
              </button>
            </p>
          )}
        </div>
      ))} */}
    </Grid>
  );
};
