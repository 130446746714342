import { TextField } from "@mui/material";

export function AdditionalRecipient(props) {
  // Pass the param to parent component
  const passParent = () => {
    // props.onChange();
  };

  // the actual DOM
  return (
    <>
      <TextField
        id="outlined-basic"
        label="Add additional recipient"
        variant="outlined"
      />
    </>
  );
}
