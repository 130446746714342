import { styled } from "@mui/material/styles";
import { TextField, Button, Input } from "@mui/material";
import { useEffect, useState } from "react";
import {
  collection,
  orderBy,
  query,
  onSnapshot,
  where,
} from "firebase/firestore";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Box,
  MenuItem,
  Select,
} from "@mui/material";
import { firestore } from "../../firebase";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { addDoc } from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const Wrapper = styled("div")({
  padding: "100px",
});

const TextFieldStyled = styled(TextField)({
  width: 350,
});

const ButtonStyled = styled(Button)({
  height: 55,
  marginLeft: 5,
  borderRadius: 2,
});

export function Credits() {
  const [email, setEmail] = useState("");
  const [credits, setCredits] = useState([]);
  // form data
  const [creditField, setCreditField] = useState(0);
  const [starteAtField, setStarteAtField] = useState("");
  const [finishedAtField, setFinishedAtField] = useState("");
  const [planIdField, setPlanIdField] = useState("");
  const [trialField, setTrialField] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      ![
        "hello@nickfromseattle.com",
        "gordana@workello.com",
        "bojanmaric13@gmail.com",
        "bojan@contentdistribution.com",
        "anrioboladze@gmail.com",
      ].includes(currentUser.email)
    ) {
      navigate("/");
    }
  }, []);

  function userSearch() {
    let initCreditInstance = query(
      collection(firestore, "users", email, "credits"),
      // where("finished_at", ">=", moment().toDate()),
      orderBy("finished_at", "desc")
    );

    onSnapshot(initCreditInstance, (querySnapshot) => {
      let creditTmpData = [];
      querySnapshot.forEach((credit) => {
        creditTmpData.push(credit.data());
      });
      setCredits(creditTmpData);
    });
  }

  async function addCredit() {
    await addDoc(collection(firestore, "users", email, "credits"), {
      credit: parseInt(creditField),
      started_at: moment(starteAtField).toDate(),
      finished_at: moment(finishedAtField).toDate(),
      plan_id: planIdField,
      trial: trialField,
    });
    setCreditField(0);
    // setStarteAtField("");
    // setFinishedAtField("");
    setPlanIdField("");
    setTrialField(false);
  }

  return (
    <Wrapper>
      {/* choose the user */}
      <TextFieldStyled
        id="outlined-basic"
        label="Please Type User Email"
        variant="outlined"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
      />
      <ButtonStyled variant="contained" onClick={userSearch}>
        Search
      </ButtonStyled>

      {/* table body */}
      {email != "" ? (
        <>
          <Box sx={{ width: "70%", float: "left" }}>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Credit Amount</TableCell>
                    <TableCell>Started At</TableCell>
                    <TableCell>Finished At</TableCell>
                    <TableCell>Plan ID</TableCell>
                    <TableCell>Trial</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {credits.map((credit) => (
                    <TableRow>
                      {/* key={row.name} */}
                      <TableCell>{credit.credit}</TableCell>
                      <TableCell>
                        {moment(credit.started_at.toDate()).format(
                          "MMMM Do YYYY"
                        )}
                      </TableCell>
                      <TableCell>
                        {moment(credit.finished_at.toDate()).format(
                          "MMMM Do YYYY"
                        )}
                      </TableCell>
                      <TableCell>{credit.plan_id}</TableCell>
                      <TableCell>{credit.triel}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box sx={{ width: "25%", float: "right", paddingTop: "15px" }}>
            <Box sx={{ marginBottom: "20px", fontWeight: "bold" }}>
              Add Credit for: {email}
            </Box>
            <TextField
              sx={{ width: "240px" }}
              id="outlined-basic"
              label="Credit Amount"
              variant="outlined"
              value={creditField}
              onChange={(e) => setCreditField(e.target.value)}
            />
            <br />
            <br />
            <LocalizationProvider
              sx={{ width: "100% !important" }}
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                label="Started At"
                value={starteAtField}
                onChange={setStarteAtField}
                renderInput={(params) => <TextField {...params} />}
              />
              <br />
              <br />
              <DatePicker
                label="Finished At"
                value={finishedAtField}
                onChange={setFinishedAtField}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <br />
            <br />
            <TextField
              sx={{ width: "240px" }}
              id="outlined-basic"
              label="Plan ID"
              variant="outlined"
              value={planIdField}
              onChange={(e) => setPlanIdField(e.target.value)}
            />
            <br />
            <br />
            <Select
              sx={{ width: "240px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={trialField}
              label="Is Trial"
              onChange={(e) => setTrialField(e.target.value)}
            >
              <MenuItem value={true}>true</MenuItem>
              <MenuItem value={false}>false</MenuItem>
            </Select>
            <br />
            <br />
            <ButtonStyled
              variant="contained"
              sx={{ width: "240px", margin: 0 }}
              onClick={addCredit}
            >
              Add Credit
            </ButtonStyled>
          </Box>
        </>
      ) : (
        ""
      )}
    </Wrapper>
  );
}
