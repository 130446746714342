import Grid from "@mui/material/Grid";
import { Typography, Alert } from "@mui/material";
import { Right } from "./Right";

export const Header = () => {
  return (
    <>
      <Grid container padding={2} alignItems={"center"} className="header">
        <Grid item xs={6}>
          <Typography className="logo">ClusterAI</Typography>
        </Grid>
        <Grid item xs={6}>
          <Right />
        </Grid>
      </Grid>
      {/* <Alert style={{ width: "100%" }} severity="success">
        We’ve resolved the grouping issue. New groupings will not be affected.
        Users who experienced stuck groupings will have their CSVs finished
        within the next 24 hours — no manual action needed. <br />
        Thank you for your patience.
      </Alert> */}
    </>
  );
};
