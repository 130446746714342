import { TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";

const AuthTypographyStyled = styled(Typography)({
  marginTop: "10px",
});

const AuthTextFieldStyled = styled(TextField)({
  color: "red",
  borderRadius: 2,
  width: "100%",
});

export const AuthTextField = ({ type, typography, name, placeholder }) => {
  return (
    <>
      <AuthTypographyStyled>{typography}</AuthTypographyStyled>
      <AuthTextFieldStyled
        variant="outlined"
        name={name}
        placeholder={placeholder}
        type={type}
      />
    </>
  );
};
