import { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { storage, firestore } from "../../firebase";
import { ref, uploadBytes } from "firebase/storage";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { generateFilename } from "../../helpers/Utils";
import { FormControl, Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { KeywordListType } from "./FormElements/KeywordListType";
import { CountryList } from "./FormElements/CountryList";
import { AdditionalRecipient } from "./FormElements/AdditionalRecipient";
import { AhrefUrl } from "./FormElements/AhrefUrl";
import { Notifications } from "../Global/Notifications";

const Input = styled("input")({
  display: "none",
});

export const FormBody = (props) => {
  // notifications state
  const [notificationState, setNotificationState] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  // form data
  const [selectedFile, setSelectedFile] = useState();
  const [keywordListType, setKeywordListType] = useState("");
  const { currentUser } = useAuth();

  // form change handlers
  const fileChangeHandler = (e) => setSelectedFile(e.target.files[0]);
  const keywordListTypeHandler = (e) => setKeywordListType(e.target.value);

  /**
   * handle the form for file upload
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    // validation
    if (selectedFile === undefined || selectedFile.type !== "text/csv") {
      setNotificationMessage("CSV FILE empty or not sutable to our platform");
      setNotificationState(true);
      return;
    }

    props.loadingStateChanger(true);

    let fileName;
    let fileFullPath;

    // file upload block
    let storageRef = ref(
      storage,
      "xlsx/" + generateFilename(selectedFile.name)
    );

    await uploadBytes(storageRef, selectedFile).then((snapshot) => {
      fileName = snapshot.metadata.name;
      fileFullPath = snapshot.metadata.fullPath;
    });

    // create firebase document
    await addDoc(
      collection(firestore, "users", currentUser.email, "groupings"),
      {
        status: "in_progress",
        groupingType: keywordListType,
        fileName,
        fileFullPath,
        timestamp: serverTimestamp(),
      }
    );

    props.loadingStateChanger(false);
  };

  return (
    <>
      <Notifications
        state={notificationState}
        message={notificationMessage}
        severity="error"
      />

      <form onSubmit={handleSubmit}>
        <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
          {/* Keyword List Type */}
          <FormControl sx={{ m: 0.5, minWidth: "48%" }}>
            <KeywordListType
              onChange={keywordListTypeHandler}
              value={keywordListType}
            />
          </FormControl>
          <FormControl sx={{ m: 0.5, minWidth: "48%" }}>
            <CountryList />
          </FormControl>
          <FormControl sx={{ m: 0.5, minWidth: "48%" }}>
            <AdditionalRecipient />
          </FormControl>
          <FormControl sx={{ m: 0.5, minWidth: "48%" }}>
            <AhrefUrl />
          </FormControl>
          <FormControl sx={{ m: 0.5, minWidth: "97%" }}>
            <label htmlFor="contained-button-file">
              <Input
                accept=".csv"
                id="contained-button-file"
                multiple
                type="file"
                onChange={fileChangeHandler}
              />
              <Button
                variant="contained"
                component="span"
                sx={{ width: "100%" }}
              >
                Choose File
              </Button>
            </label>
          </FormControl>
        </Box>
        {/* Submit */}
        <Button
          type="submit"
          sx={{ float: "right", marginRight: "30px", marginTop: "20px" }}
        >
          Submit File
        </Button>
      </form>
    </>
  );
};
