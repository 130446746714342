import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function Notifications(props) {
  // const [open, setOpen] = useState(false);

  const handleClick = () => {
    // setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    // setOpen(false);
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        sx={{ width: "400px" }}
        open={props.state}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "right", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={props.severity}
          sx={{ width: "100%" }}
        >
          {props.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

// import { Snackbar, Alert } from "@mui/material";

// export const Notifications = (props) => {
//   return (
//     <Snackbar
//       sx={{ width: "1300px" }}
//       open={props.state}
//       autoHideDuration={6000}
//       anchorOrigin={{ vertical: "top", horizontal: "right" }}
//     >
//       <Alert severity={props.severity}>{props.message}</Alert>
//     </Snackbar>
//   );
// };
