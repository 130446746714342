import { Typography, Link } from "@mui/material";

export function Menu() {
  return (
    <>
      <Typography variant="div" sx={{ marginRight: "20px" }}>
        <Link href="/">Dashboard</Link>
      </Typography>
      <Typography variant="div" sx={{ marginRight: "20px" }}>
        <Link href="/plans">Plans</Link>
      </Typography>
      <Typography variant="div" sx={{ marginRight: "20px" }}>
        <Link
          target="_blank"
          href="https://contentdistribution.com/clusterai-faq/"
        >
          Resources
        </Link>
      </Typography>
      <Typography variant="div" sx={{ marginRight: "20px" }}>
        <Link
          target="_blank"
          href="https://www.youtube.com/playlist?list=PL8hgs7nICHhdO3VeznOSOqqHnGYNzuxVg"
        >
          Watch Demos
        </Link>
      </Typography>
      <Typography variant="div">
        <Link target="_blank" href="https://workello.com">
          Hire your first Writer
        </Link>
      </Typography>
    </>
  );
}
