import { TextField } from "@mui/material";

export function AhrefUrl(props) {
  // Pass the param to parent component
  const passParent = () => {
    // props.onChange();
  };

  // the actual DOM
  return (
    <>
      <TextField id="outlined-basic" label="Ahref URL" variant="outlined" />
    </>
  );
}
