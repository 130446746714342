import { useState } from "react";
import {
  DialogTitle,
  DialogContent,
  Dialog,
  Button,
  Box,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { Notifications } from "../../Global/Notifications";
import { doc, setDoc } from "firebase/firestore";
import { firestore } from "../../../firebase";
import { useAuth } from "../../../contexts/AuthContext";
import { Loading } from "../../Global/Loading";
import axios from "axios";

/**
 * @name Questionnaire
 * @description Question form for the first login user
 */
export function Questionnaire() {
  const { currentUser } = useAuth();
  const [loadingState, setLoadingState] = useState(false);
  const [notificationState, setNotificationState] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [processInPlace, setProcessInPlace] = useState("");
  const [contentBudget, setContentBudget] = useState("");
  const [writers, setWriters] = useState("");
  const [role, setRole] = useState("");

  const firstNameHandler = (e) => setFirstName(e.target.value);
  const lastNameHandler = (e) => setLastName(e.target.value);
  const processInPlaceHandler = (e) => setProcessInPlace(e.target.value);
  const contentBudgetHandler = (e) => setContentBudget(e.target.value);
  const writersHandler = (e) => setWriters(e.target.value);
  const roleHandler = (e) => setRole(e.target.value);
  const companyHandler = (e) => setCompany(e.target.value);

  // Handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingState(true);
    setNotificationState(false);

    if (
      firstName == "" ||
      lastName == "" ||
      company == "" ||
      processInPlace == "" ||
      contentBudget == "" ||
      writers == "" ||
      role == ""
    ) {
      setNotificationMessage("Please fill all fields");
      setNotificationState(true);
      setLoadingState(false);
      return;
    }

    var data = {
      firstName,
      lastName,
      company,
      email: currentUser.email,
      processInPlace,
      contentBudget,
      writers,
      role,
    };

    var config = {
      method: "post",
      url: "https://hooks.zapier.com/hooks/catch/4289709/bfg471f",
      headers: {
        "Content-Type": "text/plain",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });

    // update user record to remove questionarie show
    await setDoc(
      doc(firestore, "users", currentUser.email),
      {
        questionnaire_fulfilled: true,
      },
      { merge: true }
    );

    // reload the document
    window.location.reload(false);
  };

  // View of questionnaire
  return (
    <Box>
      <Notifications
        state={notificationState}
        message={notificationMessage}
        severity="error"
      />
      {/* dialog body */}
      <Dialog open="true" fullWidth="md" maxWidth="md">
        <DialogTitle>Questionnaire</DialogTitle>
        {loadingState ? (
          <Loading />
        ) : (
          <DialogContent>
            {/* <DialogContentText>
              Please fulffil the Questionarie simply dummy text of the printing
              and typesetting industry. Lorem Ipsum has been the industry's
              standard dummy text ever since the 1500s, when an unknown
            </DialogContentText> */}
            <br />
            <form onSubmit={handleSubmit}>
              <Box component="form">
                {/* first name */}
                <TextField
                  id="first_name"
                  label="First Name"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  value={firstName}
                  onChange={firstNameHandler}
                />
                <br />
                <br />
                {/* last name */}
                <TextField
                  id="last_name"
                  label="Last Name"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  value={lastName}
                  onChange={lastNameHandler}
                />
                <br />
                <br />
                {/* last name */}
                <TextField
                  id="company"
                  label="Your Company"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  value={company}
                  onChange={companyHandler}
                />
                <br />
                <br />
                {/* hiring process */}
                <Select
                  displayEmpty
                  sx={{ width: "100%" }}
                  value={processInPlace}
                  onChange={processInPlaceHandler}
                >
                  <MenuItem value="">
                    <em>Do you have a hiring process in place ?</em>
                  </MenuItem>
                  <MenuItem value="Everithing is manual">
                    Everithing is manual
                  </MenuItem>
                  <MenuItem value="Glued together with Zapier">
                    Glued together with Zapier
                  </MenuItem>
                  <MenuItem value="We use applicant tracking software">
                    We use applicant tracking software
                  </MenuItem>
                </Select>
                <br />
                <br />
                {/* content budget */}
                <Select
                  displayEmpty
                  sx={{ width: "100%" }}
                  value={contentBudget}
                  onChange={contentBudgetHandler}
                >
                  <MenuItem value="">
                    <em>
                      what is your content budget over the next 12 month ?
                    </em>
                  </MenuItem>
                  <MenuItem value="less than $10,000">
                    less than $10,000
                  </MenuItem>
                  <MenuItem value="$11,000 - $25,000">
                    $11,000 - $25,000
                  </MenuItem>
                  <MenuItem value="$26,000 - $100,000">
                    $26,000 - $100,000
                  </MenuItem>
                  <MenuItem value="$100,000+">$100,000+</MenuItem>
                </Select>
                <br />
                <br />

                {/* writers */}
                <Select
                  displayEmpty
                  sx={{ width: "100%" }}
                  value={writers}
                  onChange={writersHandler}
                >
                  <MenuItem value="">
                    <em>How many writers are you hiring ?</em>
                  </MenuItem>
                  <MenuItem value="Only Me">Only Me</MenuItem>
                  <MenuItem value="1 - 2">1 - 2</MenuItem>
                  <MenuItem value="3 - 5">3 - 5</MenuItem>
                  <MenuItem value="6 - 10">6 - 10</MenuItem>
                  <MenuItem value="11+">11+</MenuItem>
                </Select>
                <br />
                <br />

                {/* role */}
                <Select
                  displayEmpty
                  sx={{ width: "100%" }}
                  value={role}
                  onChange={roleHandler}
                >
                  <MenuItem value="">
                    <em>whats's your role ?</em>
                  </MenuItem>
                  <MenuItem value="Founder">Founder</MenuItem>
                  <MenuItem value="SEO">SEO</MenuItem>
                  <MenuItem value="Marketing">Marketing</MenuItem>
                  <MenuItem value="Editing / Content Management">
                    Editing / Content Management
                  </MenuItem>
                  <MenuItem value="Recruting">Recruting</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </Box>

              {/* Submit */}
              <Button
                type="submit"
                sx={{ float: "right", marginRight: "30px", marginTop: "20px" }}
              >
                Submit Questionnaire
              </Button>
            </form>
          </DialogContent>
        )}
      </Dialog>
    </Box>
  );
}
