import { useAuth } from "../../contexts/AuthContext";
import { useEffect } from "react";

export const Logout = () => {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
  });

  return <></>;
};
