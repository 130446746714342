import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Layout } from "../Views/Layout";
import PlansData from "./Data/Plans.json";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import { styled } from "@mui/system";
import { firestore } from "../../firebase";
import {
  getDoc,
  limit,
  collection,
  doc,
  orderBy,
  query,
  onSnapshot,
  where,
  setDoc,
} from "firebase/firestore";
import moment from "moment";
import { Menu } from "../Importer/Menu";

const CardStyled = styled(Card)({
  width: 345,
  height: 250,
  float: "left",
  margin: 10,
  padding: 10,
  textAlign: "center",
  "& > .active": {
    color: "blue",
    fontWeight: "bold",
    // margin: 130,
  },
});

export const Plans = () => {
  const { currentUser } = useAuth();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH);
  const [planId, setPlanId] = useState(undefined);
  const [subscriptionId, setSubscriptionId] = useState("");
  const [creditId, setCreditId] = useState("");
  const [isCanceled, setIsCanceled] = useState(false);

  const onSubscriptionCheckout = async (stripe_plan_id) => {
    const stripe = await stripePromise;
    let userData = await getDoc(doc(firestore, "users", currentUser.email));
    let stripe_customer_id = userData.data()?.stripe_customer_id;

    // Call the backend to create the Checkout Session
    const response = await axios.get(
      process.env.REACT_APP_BACKEND_URL +
        `/StripeCheckout?custumer=${stripe_customer_id}&price=${stripe_plan_id}`
    );

    const session = await response.data;

    // When the customer clicks on the button, redirect them to Checkout.
    await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    // if (result.error) {}
  };

  const onSubscriptionPlanCancel = async (stripe_subscription_id) => {
    const response = await axios.get(
      process.env.REACT_APP_BACKEND_URL +
        `/StripeCancelSubscription?stripe_subscription_id=${stripe_subscription_id}`
    );

    await setDoc(
      doc(firestore, "users", currentUser.email, "credits", creditId),
      {
        cancels: true,
      },
      { merge: true }
    );
  };

  useEffect(async () => {
    let plan = query(
      collection(firestore, "users", currentUser.email, "credits"),
      where("finished_at", ">=", moment().toDate()),
      orderBy("finished_at", "desc"),
      limit(1)
    );

    onSnapshot(plan, (planSnapshot) => {
      planSnapshot.forEach((planDoc) => {
        setPlanId(planDoc.data().plan_id);
        setSubscriptionId(planDoc.data().subscription_id);
        setCreditId(planDoc.id);
        if (planDoc.data().cancels == true)
          setIsCanceled(planDoc.data()?.cancels);
      });
    });
  }, []);

  return (
    <Layout>
      <br />
      <Menu />
      <Grid item xs={12}>
        <br />
        <Typography variant="h3" component="div">
          Pricing & Plans
        </Typography>
        <br />
        <br />

        {PlansData.map((plan) => (
          <CardStyled>
            <CardContent
              className={
                planId == plan.stripe_plan_id && isCanceled == false
                  ? "active"
                  : ""
              }
            >
              <Typography gutterBottom variant="h5" component="div">
                {plan.name}
              </Typography>
              <Typography gutterBottom variant="h5" component="div">
                ${plan.price}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {plan.credits} Keywords Per Month
                <br />
                {plan.additional_credit_price} Per Keyword
              </Typography>
            </CardContent>
            <CardActions>
              <Grid container justify="center">
                {planId == plan.stripe_plan_id && isCanceled == false ? (
                  <Button
                    color="error"
                    size="large"
                    type="submit"
                    variant={
                      planId == plan.stripe_plan_id ? "contained" : "outlined"
                    }
                    style={{ margin: "0 auto", display: "flex" }}
                    onClick={() => onSubscriptionPlanCancel(subscriptionId)}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    size="large"
                    type="submit"
                    variant="outlined"
                    style={{ margin: "0 auto", display: "flex" }}
                    onClick={() => onSubscriptionCheckout(plan.stripe_plan_id)}
                  >
                    Select
                  </Button>
                )}
              </Grid>
            </CardActions>
          </CardStyled>
        ))}
      </Grid>
    </Layout>
  );
};
