import { useCallback, useState } from "react";
import { getAuth, updatePassword } from "firebase/auth";
import { Paper, Typography, Grid, Link } from "@mui/material";
import { AuthTextField } from "./components/AuthTextField";
import { AuthButton } from "./components/AuthButton";
import { styled } from "@mui/system";
import { useAuth } from "../../contexts/AuthContext";
import { Notifications } from "../Global/Notifications";

const GridStyled = styled(Grid)({
  width: "100%",
  background: "#3256d3",
  height: "100vh",
  paddingTop: "100px",
});

const PaperStyled = styled(Paper)({
  padding: "30px 18px",
  height: "360px",
  width: 350,
  margin: "0 auto",
});

export const Reset = () => {
  const { setLoadingEx } = useAuth();
  const [notificationState, setNotificationState] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  // handle the action after submit
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setLoadingEx(true);

    const { password, repeat_password } = e.target.elements;
    const auth = getAuth();
    try {
      // throw "Parameter is not a number!";
      await updatePassword(auth, "anrioboladze@gmail.com");
    } catch (e) {
      setLoadingEx(false);
      console.log(e);
      alert(e.message); // should show notification
    }
  }, []);

  return (
    <GridStyled>
      <Notifications
        state={notificationState}
        message={notificationMessage}
        severity="error"
      />
      <PaperStyled elevation={3}>
        <form onSubmit={handleSubmit}>
          <Typography className="logo">ClusterAI</Typography>
          <AuthTextField
            type="password"
            typography="New Password"
            name="password"
          />
          <AuthTextField
            type="password"
            typography="Repeat Password"
            name="repeat_password"
          />
          <AuthButton label="Update Password" />
        </form>
        <Typography>
          Don't have an account? <Link href="signup">Sign up</Link>
          <br />
          Forgot your password? <Link href="forgot_password">Recover</Link>
        </Typography>
      </PaperStyled>
    </GridStyled>
  );
};
