import { Header } from "../Header/Header";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "../../assets/css/App.css";

/**
 * @name Layout
 * @description Wraps the Views into structure
 */
export const Layout = ({ children }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Header />
      <Grid container alignItems={"center"}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <main>{children}</main>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </Box>
  );
};
