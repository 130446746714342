import { useEffect, useState } from "react";
import { Layout } from "../Views/Layout";
import { HistoryList } from "../History/HistoryList";
import { Questionnaire } from "./FormElements/Questionnaire";
import { FormDialog } from "./FormDialog";
import { getDoc, doc } from "firebase/firestore";
import { firestore } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";

export const Importer = () => {
  const { currentUser } = useAuth();
  const [questionnaireFulfilled, setQuestionnaireFulfilled] = useState(true);

  useEffect(async () => {
    // code here
    let userData = await getDoc(doc(firestore, "users", currentUser.email));
    if (userData.data()?.questionnaire_fulfilled == undefined) {
      setQuestionnaireFulfilled(false);
    }
  }, []);

  return (
    <Layout>
      {/* check if user does not fuffill the form */}
      {questionnaireFulfilled ? (
        <>
          <FormDialog />
          <HistoryList />
        </>
      ) : (
        <Questionnaire />
      )}
    </Layout>
  );
};
