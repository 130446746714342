import * as React from "react";
import {
  Typography,
  Avatar,
  ListItemAvatar,
  ListItemText,
  Divider,
  ListItem,
  List,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  collection,
  orderBy,
  query,
  limit,
  onSnapshot,
  startAfter,
  getDoc,
  doc,
} from "firebase/firestore";
import { firestore } from "../../firebase";

const Wrapper = styled("div")({
  padding: "100px",
});

export function ActionLogs() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [lastSnapshot, setLastSnapshot] = useState(null);

  useEffect(() => {
    if (
      ![
        "hello@nickfromseattle.com",
        "gordana@workello.com",
        "bojanmaric13@gmail.com",
        "bojan@contentdistribution.com",
        "anrioboladze@gmail.com",
      ].includes(currentUser.email)
    ) {
      navigate("/");
    }

    // Get the data
    let initQueryInstance = query(
      collection(firestore, "logger"),
      orderBy("created_at", "desc")
      //   limit(4)
    );

    onSnapshot(initQueryInstance, (querySnapshot) => {
      const data = [];
      // console.log(querySnapshot);
      querySnapshot.forEach(async (doc) => {
        data.push({ ...doc.data() });
      });

      data.forEach(async (element, index) => {
        let details = await getDoc(element.ref);

        data[index] = { data: data[index], details: details.data() };
        console.log(data[index]);
      });

      setTimeout(() => {
        // console.log(data);
        setData(data);
      }, "2000");
    });
  }, []);

  return (
    <Wrapper>
      {data.map((el) => (
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              {/* <Avatar alt={el.data.email} src="/static/images/avatar/1.jpg" /> */}
            </ListItemAvatar>
            <ListItemText
              primary={
                el.details.timestamp
                  .toDate()
                  .toDateString()
                  .replace(/^\S+\s/, "") +
                " - " +
                el.details.keywords_main
              }
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {el.data.email} <br />
                    keywords total - {el.details.keywords_total}
                    <br />
                    keywords sent - {el.details.keywords_sent}
                    <br />
                    keywords received - {el.details.keywords_received}
                    <br />
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </List>
      ))}
    </Wrapper>
  );
}
