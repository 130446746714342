import { AuthProvider } from "./contexts/AuthContext";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";
import { Login } from "./components/User/Login";
import { Logout } from "./components/User/Logout";
import { SignUp } from "./components/User/SignUp";
import { Reset } from "./components/User/Reset";
import { ForgotPassword } from "./components/User/ForgotPassword";
import { Plans } from "./components/Payment/Plans";
import { Importer } from "./components/Importer/Importer";
import { Credits } from "./components/Admin/Credits";
import { ActionLogs } from "./components/Admin/ActionLogs";
import { ActionLogsGrid } from "./components/Admin/ActionLogsGrid";

function Private({ children }) {
  const { currentUser } = useAuth();
  if (!currentUser) return <Navigate to="/login" />;
  return children;
}

function Public({ children }) {
  const { currentUser } = useAuth();
  if (currentUser) return <Navigate to="/" />;
  return children;
}

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Private>
                <Importer />
              </Private>
            }
          />
          <Route
            path="/plans"
            element={
              <Private>
                <Plans />
              </Private>
            }
          />
          <Route
            path="/logout"
            element={
              <Private>
                <Logout />
              </Private>
            }
          />
          <Route
            path="/login"
            element={
              <Public>
                <Login />
              </Public>
            }
          />
          <Route
            path="/forgot_password"
            element={
              <Public>
                <ForgotPassword />
              </Public>
            }
          />
          <Route
            path="/reset"
            element={
              <Public>
                <Reset />
              </Public>
            }
          />
          <Route
            path="/signup"
            element={
              <Public>
                <SignUp />
              </Public>
            }
          />
          {/* admin routes */}
          <Route
            path="/admin/credits"
            element={
              <Private>
                <Credits />
              </Private>
            }
          />
          <Route
            path="/admin/logs"
            element={
              <Private>
                <ActionLogs />
              </Private>
            }
          />
          <Route
            path="/admin/logs/grid"
            element={
              <Private>
                <ActionLogsGrid />
              </Private>
            }
          />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
