import { useCallback, useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Paper, Typography, Grid, Link } from "@mui/material";
import { AuthTextField } from "./components/AuthTextField";
import { AuthButton } from "./components/AuthButton";
import { styled } from "@mui/system";
import { useAuth } from "../../contexts/AuthContext";
import { Notifications } from "../Global/Notifications";

const GridStyled = styled(Grid)({
  width: "100%",
  background: "#3256d3",
  height: "100vh",
  paddingTop: "100px",
});

const PaperStyled = styled(Paper)({
  padding: "30px 18px",
  height: "360px",
  width: 350,
  margin: "0 auto",
});

export const ForgotPassword = () => {
  const { setLoadingEx } = useAuth();
  const [notificationState, setNotificationState] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [messageRender, setMessageRender] = useState(false);

  // handle the action after submit
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setLoadingEx(true);

    const { email } = e.target.elements;
    const auth = getAuth();

    try {
      setMessageRender(true);
      setLoadingEx(false);
      await sendPasswordResetEmail(auth, email.value);
    } catch (e) {
      setLoadingEx(false);
      alert(e.message); // should show notification
    }
  }, []);

  return (
    <GridStyled>
      {messageRender == false ? (
        <>
          <Notifications
            state={notificationState}
            message={notificationMessage}
            severity="error"
          />
          <PaperStyled elevation={3}>
            <form onSubmit={handleSubmit}>
              <Typography className="logo">ClusterAI</Typography>
              <AuthTextField type="email" typography="Email" name="email" />
              <AuthButton label="Recover" />
            </form>
            <Typography>
              Already have an account? <Link href="login">Login</Link>
            </Typography>
          </PaperStyled>
        </>
      ) : (
        <center>
          <Typography sx={{ color: "white", fontSize: "24px" }}>
            We sent the recovery link to your email, please check the spam
            folder as well
          </Typography>
          <Typography sx={{ color: "#09142" }}>
            <br />
            Already recover the password? - &nbsp;
            <Link href="login" sx={{ color: "#09142d" }}>
              Login
            </Link>
            <br />
            Did not get the password recovery link ? - &nbsp;
            <Link href="forgot_password" sx={{ color: "#09142d" }}>
              Recover Again
            </Link>
          </Typography>
        </center>
      )}
    </GridStyled>
  );
};
