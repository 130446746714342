import * as React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  collection,
  orderBy,
  query,
  limit,
  onSnapshot,
  startAfter,
  getDoc,
  doc,
} from "firebase/firestore";
import { firestore } from "../../firebase";
import moment from "moment";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const columns = [
  { field: "id", headerName: "ID", width: 30 },
  { field: "email", headerName: "Email", width: 230 },
  { field: "fileFullPath", headerName: "Input File", width: 230 },
  { field: "resultFileFullPath", headerName: "Result File", width: 230 },
  { field: "timestamp", headerName: "Date", width: 250 },
  { field: "keywords_main", headerName: "Main Keyword", width: 150 },
  { field: "keywords_total", headerName: "Total", width: 80 },
  { field: "keywords_sent", headerName: "Sent", width: 80 },
  { field: "keywords_received", headerName: "Received", width: 80 },
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export function ActionLogsGrid() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [lastSnapshot, setLastSnapshot] = useState(null);
  const storage = getStorage();

  function downloadFile(path) {
    getDownloadURL(ref(storage, path))
      .then((url) => {
        window.open(url, "_blank").focus();
      })
      .catch((error) => {
        // Handle any errors
      });
  }

  const handleCellClick = (param, event) => {
    if (
      param.field === "fileFullPath" ||
      param.field === "resultFileFullPath"
    ) {
      downloadFile(param.value);
    }
    // console.log("Clicked cell value:", param.value);
    // console.log("Clicked cell row id:", param.id);
    // console.log("Clicked cell column field:", param.field);
  };

  useEffect(() => {
    if (
      ![
        "hello@nickfromseattle.com",
        "gordana@workello.com",
        "bojanmaric13@gmail.com",
        "bojan@contentdistribution.com",
        "anrioboladze@gmail.com",
      ].includes(currentUser.email)
    ) {
      navigate("/");
    }

    // Get the data
    let initQueryInstance = query(
      collection(firestore, "logger"),
      orderBy("created_at", "desc"),
      limit(100)
    );

    onSnapshot(initQueryInstance, (querySnapshot) => {
      const tmpData = [];
      const tmpDetails = [];
      // console.log(querySnapshot);
      querySnapshot.forEach(async (doc) => {
        tmpData.push({ ...doc.data() });
      });
      // console.log(tmpData);

      tmpData.forEach(async (element, index) => {
        let details = await getDoc(element.ref);
        // data[index] = { data: data[index], details: details.data() };
        let tmpData = details.data();

        // cobnvert timestampo top date moment
        tmpData["timestamp"] = moment(tmpData["timestamp"].toDate()).format(
          "MMM Do YYYY, h:mm:ss a"
        );
        // tmpData["timestamp"] = tmpData["timestamp"].toDate();
        // tmpDetails.push({ id: index, ...details.data() });
        tmpDetails.push({ id: index, email: element.email, ...tmpData });
        // console.log(tmpDetails);
      });

      setTimeout(() => {
        setData(tmpDetails);
      }, "2000");
    });
  }, []);

  return (
    <div style={{ height: 700, width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        components={{
          Toolbar: CustomToolbar,
        }}
        onCellClick={handleCellClick}
        onColumnCli
      />
    </div>
  );
}
