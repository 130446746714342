import { useEffect, useState } from "react";
import { firestore } from "../../firebase";
import {
  Typography,
  IconButton,
  ListItemAvatar,
  ListItemText,
  ListItem,
  List,
  Link,
} from "@mui/material";
import {
  collection,
  orderBy,
  query,
  limit,
  onSnapshot,
  startAfter,
  getDocs,
  where,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";
import { ExitToAppRounded } from "@mui/icons-material";
import moment from "moment";

export const Right = () => {
  const { currentUser } = useAuth();
  const [credit, setCredit] = useState("");
  const [expire, setExpire] = useState("");

  useEffect(async () => {
    // todo: get all credits which is between this dates

    let initCreditInstance = query(
      collection(firestore, "users", currentUser.email, "credits"),
      where("finished_at", ">=", moment().toDate()),
      orderBy("finished_at")
    );

    onSnapshot(initCreditInstance, (querySnapshot) => {
      let credit = 0;
      querySnapshot.forEach((doc) => {
        credit += doc.data().credit;
        setExpire(
          moment(doc.data().finished_at.toDate()).format("MMMM Do YYYY")
        );
      });
      setCredit(credit);
    });
  }, []);

  return (
    <List sx={{ width: "100%", maxWidth: 360, float: "right" }}>
      <ListItem>
        <ListItemText
          sx={{ color: "#3256d3" }}
          primary={currentUser.email}
          secondary={
            <Typography sx={{ color: "#b3b3b3", fontSize: "13px" }}>
              {/* <Link href="plans"></Link> */}
              {credit} credit untill {expire}
            </Typography>
          }
        />
        <ListItemAvatar>
          <Link href="logout">
            <IconButton
              aria-label="logout"
              sx={{
                backgroundColor: "#ededed",
                borderRadius: "5px",
              }}
            >
              <ExitToAppRounded sx={{ color: "#4647486e" }} />
            </IconButton>
          </Link>
        </ListItemAvatar>
      </ListItem>
    </List>
  );
};
