// import * as React from "react";
import { CircularProgress, Typography, Box } from "@mui/material";
import { margin, styled } from "@mui/system";

// Styled Box
const MainBoxStyled = styled(Box)({
  position: "relative",
  display: "inline-flex",
});

const ValueBoxStyled = styled(Box)({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export function CircularProgressWithLabel(props) {
  return (
    <MainBoxStyled>
      <CircularProgress variant="determinate" {...props} />
      <ValueBoxStyled>
        <Typography variant="caption">
          {`${Math.round(props.value)}%`}
        </Typography>
      </ValueBoxStyled>
    </MainBoxStyled>
  );
}
