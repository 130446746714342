import { useCallback } from "react";
import { firestore } from "../../firebase";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import {
  addDoc,
  collection,
  serverTimestamp,
  doc,
  setDoc,
} from "firebase/firestore";
import { Paper, Typography, Grid, Link } from "@mui/material";
import { styled } from "@mui/system";
import { Notifications } from "../Global/Notifications";
import { AuthTextField } from "./components/AuthTextField";
import { AuthButton } from "./components/AuthButton";
import axios from "axios";
import moment from "moment";

const GridStyled = styled(Grid)({
  width: "100%",
  background: "#3256d3",
  height: "100vh",
  paddingTop: "100px",
});

const PaperStyled = styled(Paper)({
  padding: "30px 18px",
  height: 520,
  width: 350,
  margin: "0 auto",
});

export const SignUp = () => {
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    const { email, password, full_name } = e.target.elements;
    const auth = getAuth();

    try {
      // create user with email and password
      await createUserWithEmailAndPassword(auth, email.value, password.value);

      // get the customer
      const StripeCreateCustomers = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/StripeCreateCustomers",
        {
          params: {
            email: email.value,
            name: full_name.value,
          },
        }
      );

      await updateProfile(auth.currentUser, {
        displayName: full_name.value,
      });

      await setDoc(doc(firestore, "users", email.value), {
        stripe_customer_id: StripeCreateCustomers.data.id,
      });

      // set the credit for the user
      await addDoc(collection(firestore, "users", email.value, "credits"), {
        credit: 200,
        started_at: serverTimestamp(),
        finished_at: moment().add(1, "M").toDate(),
        trial: true,
      });
    } catch (e) {
      alert(e.message);
    }
  }, []);

  return (
    <GridStyled>
      <Notifications />
      <PaperStyled elevation={3}>
        <form onSubmit={handleSubmit}>
          <Typography className="logo">ClusterAI</Typography>
          <AuthTextField type="text" typography="Full Name" name="full_name" />
          <AuthTextField type="email" typography="Email" name="email" />
          <AuthTextField
            type="password"
            typography="Password"
            name="password"
          />
          <AuthTextField
            type="password"
            typography="Repeat Password"
            name="repeat_password"
          />
          <AuthButton label="sign up" />
        </form>
        <Typography>
          Already have an account? <Link href="login">Login</Link>
        </Typography>
      </PaperStyled>
    </GridStyled>
    // <>
    //   <h1>Sign Up</h1>
    //   <form onSubmit={handleSubmit}>
    //     <input name="email" placeholder="email" type="email" />
    //     <input name="password" placeholder="password" type="password" />
    //     <button type="submit">Sign Up</button>
    //   </form>
    // </>
  );
};
