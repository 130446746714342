import { Select, FormHelperText, MenuItem } from "@mui/material";
import { useState } from "react";

export function KeywordListType(props) {
  // const [listType, setListType] = useState("");

  // Pass the param to parent component
  const handleChange = (event) => {
    props.onChange(event);
    // setListType(event.target.value);
  };

  // the actual DOM
  return (
    <>
      <FormHelperText>Keyword list type</FormHelperText>
      <Select value={props.value} displayEmpty onChange={handleChange}>
        <MenuItem value="" sx={{ color: "blue" }}>
          <em>Choose Keyword List Type</em>
        </MenuItem>
        <MenuItem value="ahrefs">Ahrefs</MenuItem>
        <MenuItem value="gsc">Google Search Console</MenuItem>
        <MenuItem value="semrush">Semrush</MenuItem>
        <MenuItem value="custom">Custom</MenuItem>
      </Select>
    </>
  );
}
