import { Button } from "@mui/material";
import { styled } from "@mui/system";

const AuthButtonStyled = styled(Button)({
  color: "#ffffff",
  background: "#3256D3",
  width: "100%",
  height: "50px",
  marginTop: "30px",
  marginBottom: "10px",
  borderRadius: 3,
});

export const AuthButton = ({ label }) => {
  return (
    <AuthButtonStyled variant="contained" type="submit">
      {label}
    </AuthButtonStyled>
  );
};
