import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import { styled } from "@mui/system";

const BoxStyled = styled(Box)({
  background: "white",
  width: "100%",
  height: "100vh",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
});

export const Loading = () => {
  return (
    <BoxStyled>
      <CircularProgress />
    </BoxStyled>
  );
};
