import { useState } from "react";
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/AttachFile";
import { FormBody } from "./FormBody";
import { Box, styled } from "@mui/system";
import { Loading } from "../Global/Loading";
import { Menu } from "./Menu";

const ImportButtonStyled = styled(Button)({
  // marginTop: "14px",
});

export function FormDialog() {
  const [open, setOpen] = useState(false);
  const [loadingState, setLoadingState] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Box display="flex">
        <Box flexGrow={1} sx={{ marginTop: "15px" }}>
          <Menu />
        </Box>
        <Box>
          <ImportButtonStyled
            sx={{ marginTop: "10px" }}
            startIcon={<DeleteIcon />}
            variant="outlined"
            onClick={handleClickOpen}
          >
            Import CSV
          </ImportButtonStyled>
        </Box>
      </Box>

      {/* dialog body */}
      <Dialog open={open} onClose={handleClose} fullWidth="md" maxWidth="md">
        <DialogTitle>Import data</DialogTitle>
        {loadingState ? (
          <Loading />
        ) : (
          <DialogContent>
            <DialogContentText>
              To subscribe to this website, please enter your email address
              here. We will send updates occasionally.
            </DialogContentText>
            <FormBody loadingStateChanger={setLoadingState} />
          </DialogContent>
        )}
      </Dialog>
    </Box>
  );
}
