import { Select, FormHelperText, MenuItem } from "@mui/material";
import { useState } from "react";
import Countries from "../Data/Countries.json";

export function CountryList(props) {
  const [country, setCountry] = useState("");

  // Pass the param to parent component
  const handleChange = (event) => {
    // props.onChange();
    setCountry(event.target.value);
  };

  // the actual DOM
  return (
    <>
      <FormHelperText>Without label</FormHelperText>
      <Select value={country} displayEmpty onChange={handleChange}>
        <MenuItem value="" sx={{ color: "blue" }}>
          <em>Choose Country</em>
        </MenuItem>
        {Countries.map((country) => (
          <MenuItem value={country.label}>{country.label}</MenuItem>
        ))}
      </Select>
    </>
  );
}
